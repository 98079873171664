<template>
  <div class="mt-3 mb-3">
    <v-text-field
      ref="search"
      v-model="search"
      hide-details
      single-line
      clearable
      append-icon="mdi-magnify"
      class="mb-5"
      placeholder="Enter keywords..."
    />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      :show-expand="showExpand"
      :single-expand="singleExpand"
      :hide-default-footer="false"
      :items-per-page="10"
      item-key="name"
      :item-class="itemClass"
      class="elevation-1 hosting-server-domains-table"
    >
      <template
        #[`item.name`]="{ item }"
      >
        <a
          :href="'https://' + item.name"
          target="_blank"
          @dblclick="$helper.openWindow(item.name)"
          @click.prevent=""
        >{{ item.name }}</a>
      </template>

      <template
        #[`item.message`]="{ item }"
      >
        <div v-if="item.address_record_real !== item.hosting_server_ip">
          <div
            class="red--text small"
          >
            {{ $t('hosting.servers.domains.table.messages.realIpNotMatchIpServer') }}
          </div>
          <div class="grey--text small">
            Real IP: <strike>{{ item.address_record_real }}</strike> | Server IP: {{ item.hosting_server_ip }}
          </div>
        </div>
      </template>

      <template #[`item.hosting_server_ip`]="{ item }">
        <div class="text-no-wrap">
          <v-chip-group
            active-class="primary--text"
            column
          >
            <v-chip
              small
              class="ma-2 chip-with-copy"
              close
              close-icon="mdi-content-copy"
              @click:close="$copyToClipboard(item.hosting_server_ip)"
            >
              {{ item.hosting_server_ip }}
            </v-chip>
          </v-chip-group>
        </div>
      </template>

      <template
        #[`item.hosting_server_ssl`]="{ item }"
      >
        <v-layout justify-center>
          <v-flex
            xs12
            md4
          >
            <v-switch
              :input-value="item.hosting_server_ssl"
              hide-details
              disabled
            />
          </v-flex>
        </v-layout>
      </template>

      <template
        #[`item.actions`]="{ item }"
      >
        <div class="actions-cell">
          <table-btn
            :item="item"
            :loading="item.loading"
            :tooltip-text="$t('hosting.servers.domains.table.btn.sync')"
            :icon="`autorenew`"
            :can="`hosting.servers.sync`"
            :fn="domainSync"
          />
          <table-btn
            :item="item"
            :tooltip-text="$t('hosting.servers.domains.table.btn.updateDomain')"
            :icon="`pencil`"
            :can="`hosting.servers.update`"
            :fn="updateDomain"
          />
          <table-btn
            :item="item"
            :tooltip-text="$t('hosting.servers.domains.table.btn.changeOwner')"
            :icon="`account-switch`"
            :can="`hosting.servers.update`"
            :fn="changeDomainOwner"
          />
          <table-btn
            :item="item"
            :tooltip-text="$t('hosting.servers.domains.table.btn.domainTransfer')"
            :icon="`truck-fast-outline`"
            :can="`hosting.servers.update`"
            :fn="domainTransfer"
          />
          <table-btn
            :item="item"
            :disabled="false"
            :tooltip-text="$t('hosting.servers.domains.table.btn.removeDomain')"
            :icon="`delete`"
            :can="`hosting.servers.remove`"
            :fn="removeDomain"
          />
        </div>
      </template>
    </v-data-table>
    <dialogs-server-domain
      :dialog="serverDomainDialog"
      :edited-item="serverDomainEditedItem"
      :action="'update'"
      @close="serverDomainDialogClose"
    />
    <dialogs-change-domain-owner
      :dialog="changeDomainOwnerDialog"
      :edited-item="changeDomainOwnerEditedItem"
      @close="changeDomainOwnerDialogClose"
    />
    <dialogs-domain-transfer
      :dialog="domainTransferDialog"
      :edited-item="domainTransferEditedItem"
      @close="domainTransferDialogClose"
    />
  </div>
</template>

<script>
import DialogsServerDomain from '../dialogs/ServerDomain'
import DialogsChangeDomainOwner from '../dialogs/ChangeDomainOwner'
import DialogsDomainTransfer from '../dialogs/DomainTransfer'
export default {
  name: 'ServerDetails',
  components: {
    DialogsServerDomain,
    DialogsChangeDomainOwner,
    DialogsDomainTransfer
  },
  props: {
    params: {
      type: Object,
      default: () => {
      }
    },
    parentSearch: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    //
    search: '',
    showExpand: false,
    singleExpand: true,
    //
    serverDomainDialog: false,
    serverDomainEditedItem: {},
    //
    changeDomainOwnerDialog: false,
    changeDomainOwnerEditedItem: {},
    //
    domainTransferDialog: false,
    domainTransferEditedItem: {}
  }),
  computed: {
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    }
  },
  watch: {
    params (val) {
      this.items = val.domains
    },
    parentSearch(v) {}
  },
  mounted () {
    this.items = this.params.domains
    this.headers = [
      {
        text: this.$t('hosting.servers.domains.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('hosting.servers.domains.table.message'),
        value: 'message'
      },
      {
        text: this.$t('hosting.servers.domains.table.ip'),
        value: 'hosting_server_ip',
        width: '100px',
        align: 'center'
      },
      {
        text: this.$t('hosting.servers.domains.table.owner'),
        value: 'hosting_server_user',
        width: '150px',
        align: 'center'
      },
      {
        text: this.$t('hosting.servers.domains.table.ssl'),
        value: 'hosting_server_ssl',
        width: '100px',
        align: 'center'
      },
      {
        text: this.$t('hosting.servers.domains.table.actions'),
        value: 'actions',
        width: '150px',
        align: 'center',
        sortable: false
      }
      /* ,{ text: '', value: 'data-table-expand' } */
    ]

    if (this.parentSearch) {
      this.search = this.parentSearch
    }
  },
  methods: {
    async domainSync(item) {
      this.$set(item, 'loading', true)
      try {
        const response = await this.$store.dispatch('hostingServers/domainSync', {
          id: item.hosting_server_id,
          domain: item.name
        })
        if (response.success === true) {
          // Object.assign(item, response.result)
          // await this.$toast.success(this.$t('domains.cloudflare.zones.sync.success'))
        } else {
          await this.$toast.error(response.errors)
        }
      } catch (e) {
        console.log(e)
      } finally {
        // this.$set(item, 'loading', true)
      }
    },
    async removeDomain(item) {
      this.$set(item, 'loading', true)
      try {
        const response = await this.$store.dispatch('hostingServers/domainRemove', {
          id: item.hosting_server_id,
          domain: item.name,
          user: item.hosting_server_user
        })
        if (response.success === true) {
          // Object.assign(item, response.result)
          // await this.$toast.success(this.$t('domains.cloudflare.zones.sync.success'))
        } else {
          await this.$toast.error(response.errors)
        }
      } catch (e) {
        console.log(e)
      } finally {
        // this.$set(item, 'loading', true)
      }
    },
    updateDomain(item) {
      this.serverDomainEditedItem = Object.assign({}, this.serverDomainEditedItem, {
        domain: item.name,
        id: item.hosting_server_id,
        user: item.hosting_server_user,
        ip: item.hosting_server_ip
      })
      this.serverDomainDialog = true
    },
    serverDomainDialogClose() {
      this.serverDomainDialog = false
      this.serverDomainEditedItem = {}
    },
    changeDomainOwner(item) {
      this.changeDomainOwnerEditedItem = Object.assign({}, this.changeDomainOwnerEditedItem, {
        domain: item.name,
        id: item.hosting_server_id,
        currentOwner: item.hosting_server_user
      })
      this.changeDomainOwnerDialog = true
    },
    changeDomainOwnerDialogClose () {
      this.changeDomainOwnerDialog = false
    },
    domainTransfer(item) {
      this.domainTransferDialog = true
      this.domainTransferEditedItem = Object.assign({}, this.domainTransferEditedItem, {
        domain: item.name,
        id: item.hosting_server_id
      })
    },
    domainTransferDialogClose() {
      this.domainTransferDialog = false
      this.domainTransferEditedItem = {}
    },
    itemClass(item) {
      let classes
      if (
        (!item.address_record_real || !item.address_record) ||
        (item.address_record_real !== item.hosting_server_ip)
      ) {
        classes += ' deep-orange lighten-5 '
      }
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
        item.loading = true
      } else {
        item.loading = false
      }
      return classes
    }
  }
}
</script>

<style lang="scss">
.hosting-server-domains-table {
  .v-data-table-header {
    background: #F5F5F5;
  }
}
</style>
