var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"default-table-headers":[],"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"form-default-values":_vm.formDefaultValues,"resource":_vm.resource,"expanded":_vm.expanded,"show-expand":_vm.$can('hosting.servers.view') ? _vm.showExpand : false,"single-expand":_vm.singleExpand,"item-class-fn":_vm.itemClassFn},on:{"inputChange":_vm.handleChange,"dialogChange":_vm.handleDialog,"changeSelected":_vm.handleSelected,"currentItems":_vm.handleCurrentItems},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"mb-1 text-no-wrap"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.notes || '')+" ")])])]}},{key:"item.hp.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"mb-1 text-no-wrap"},[_vm._v(" "+_vm._s(item.hp.name || '—')+" ")]),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.hp.description || '—')+" ")])])]}},{key:"item.ip",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((item.ip),function(ip,key){return _c('v-chip',{key:key,staticClass:"ma-2 chip-with-copy",attrs:{"small":"","close":"","close-icon":"mdi-content-copy"},on:{"click:close":function($event){return _vm.$copyToClipboard(ip)}}},[_vm._v(" "+_vm._s(ip)+" ")])}),1)]}},{key:"item.proxy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.proxy.name)+" ")]),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.proxy.id ? ((item.proxy.type) + "://" + (item.proxy.host) + ":" + (item.proxy.port)) : '—')+" ")])]}},{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('Reload Apache, Nginx, PHP-FPM'),"disabled":item.importDomainsLoading || item.loading || !item.with_cp,"icon":"reload","can":"hosting.servers.update","fn":_vm.restartWeb}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('hosting.servers.table.btn.usersCredentials'),"icon":"account-key-outline","can":"credentials.view","fn":_vm.getUserCredentials}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('hosting.servers.table.btn.credentials'),"icon":"key-chain-variant","can":"hosting.servers.auth","fn":_vm.getCredentials}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$helper.isWin() === true ? _vm.$t('hosting.servers.table.btn.openWinSCP') : _vm.$t('hosting.servers.table.btn.copySFTPLink'),"icon":"link-variant","can":"hosting.servers.auth","fn":_vm.getSFTPCredentials}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('hosting.servers.table.btn.importDomains'),"disabled":item.importDomainsLoading || item.loading || !item.with_cp,"loading":item.importDomainsLoading || item.loading,"icon":"database-import-outline","can":"hosting.servers.import","fn":_vm.importDomains}}),(item.with_cp)?_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('hosting.servers.table.btn.addDomain'),"disabled":item.loading,"icon":"plus","can":"hosting.servers.create","fn":_vm.addDomain}}):_vm._e(),(item.with_cp)?_c('drop-menu',{attrs:{"actions-list":_vm.serverInfoMenu,"item":item,"loading":item.serverInfoLoading,"icon":"lifebuoy"}}):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(!!item.with_cp)?_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{'v-data-table__expand-icon--active' : isExpanded},attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(_vm.detailsComponent,{tag:"component",attrs:{"params":item,"parent-search":_vm.getSearchQuery}})],1)]}},{key:"item.team",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.team)+" ")])]}}],null,true)}),_c('dialogs-server-domain',{attrs:{"dialog":_vm.serverDomainDialog,"edited-item":_vm.serverDomainEditedItem,"action":'create'},on:{"close":_vm.serverDomainDialogClose}})],1)],1),_c('dialog-credentials',{ref:"dialogCredentials"}),_c('dialog-confirmation',{ref:"confirmationDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }