var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 mb-3"},[_c('v-text-field',{ref:"search",staticClass:"mb-5",attrs:{"hide-details":"","single-line":"","clearable":"","append-icon":"mdi-magnify","placeholder":"Enter keywords..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1 hosting-server-domains-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"show-expand":_vm.showExpand,"single-expand":_vm.singleExpand,"hide-default-footer":false,"items-per-page":10,"item-key":"name","item-class":_vm.itemClass},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'https://' + item.name,"target":"_blank"},on:{"dblclick":function($event){return _vm.$helper.openWindow(item.name)},"click":function($event){$event.preventDefault();}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [(item.address_record_real !== item.hosting_server_ip)?_c('div',[_c('div',{staticClass:"red--text small"},[_vm._v(" "+_vm._s(_vm.$t('hosting.servers.domains.table.messages.realIpNotMatchIpServer'))+" ")]),_c('div',{staticClass:"grey--text small"},[_vm._v(" Real IP: "),_c('strike',[_vm._v(_vm._s(item.address_record_real))]),_vm._v(" | Server IP: "+_vm._s(item.hosting_server_ip)+" ")],1)]):_vm._e()]}},{key:"item.hosting_server_ip",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},[_c('v-chip',{staticClass:"ma-2 chip-with-copy",attrs:{"small":"","close":"","close-icon":"mdi-content-copy"},on:{"click:close":function($event){return _vm.$copyToClipboard(item.hosting_server_ip)}}},[_vm._v(" "+_vm._s(item.hosting_server_ip)+" ")])],1)],1)]}},{key:"item.hosting_server_ssl",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-switch',{attrs:{"input-value":item.hosting_server_ssl,"hide-details":"","disabled":""}})],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions-cell"},[_c('table-btn',{attrs:{"item":item,"loading":item.loading,"tooltip-text":_vm.$t('hosting.servers.domains.table.btn.sync'),"icon":"autorenew","can":"hosting.servers.sync","fn":_vm.domainSync}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('hosting.servers.domains.table.btn.updateDomain'),"icon":"pencil","can":"hosting.servers.update","fn":_vm.updateDomain}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('hosting.servers.domains.table.btn.changeOwner'),"icon":"account-switch","can":"hosting.servers.update","fn":_vm.changeDomainOwner}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('hosting.servers.domains.table.btn.domainTransfer'),"icon":"truck-fast-outline","can":"hosting.servers.update","fn":_vm.domainTransfer}}),_c('table-btn',{attrs:{"item":item,"disabled":false,"tooltip-text":_vm.$t('hosting.servers.domains.table.btn.removeDomain'),"icon":"delete","can":"hosting.servers.remove","fn":_vm.removeDomain}})],1)]}}],null,true)}),_c('dialogs-server-domain',{attrs:{"dialog":_vm.serverDomainDialog,"edited-item":_vm.serverDomainEditedItem,"action":'update'},on:{"close":_vm.serverDomainDialogClose}}),_c('dialogs-change-domain-owner',{attrs:{"dialog":_vm.changeDomainOwnerDialog,"edited-item":_vm.changeDomainOwnerEditedItem},on:{"close":_vm.changeDomainOwnerDialogClose}}),_c('dialogs-domain-transfer',{attrs:{"dialog":_vm.domainTransferDialog,"edited-item":_vm.domainTransferEditedItem},on:{"close":_vm.domainTransferDialogClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }