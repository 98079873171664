<template>
  <dialog-form
    :dialog="dialog"
    :form-title="formTitle"
    :form-scheme="formScheme"
    :save-loading="saveLoading"
    :edited-item="serverDomainEditedItem"
    @changeEditedItem="serverDomainChangeEditedItem"
    @save="serverDomainSave"
    @close="serverDomainDialogClose"
  />
</template>

<script>
export default {
  name: 'ServerDomain',
  props: {
    dialog: Boolean,
    action: {
      type: String,
      default: 'create'
    },
    editedItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    formTitle: '',
    formScheme: {},
    saveLoading: false,
    serverDomainEditedItem: {}
  }),
  watch: {
    async dialog() {
      this.formScheme.ip.items = []
      this.formScheme.user.items = []
      this.serverDomainEditedItem = Object.assign({}, this.editedItem)
      if (this.$helper.has(this.editedItem, 'id')) {
        this.formScheme.ip.items = await this.$store.dispatch('hostingServers/getHostingServerIp', { id: this.editedItem.id })
        this.formScheme.user.items = await this.$store.dispatch('hostingServers/getHostingServerUsers', { id: this.editedItem.id })
      }
    }
  },
  async mounted () {
    this.formTitle = this.action === 'update'
      ? this.$t('hosting.servers.domains.dialog.updateTitle')
      : this.$t('hosting.servers.domains.dialog.addTitle')
    this.formScheme = {
      domain: {
        type: 'text',
        label: this.$t('hosting.servers.domains.dialog.name'),
        createRules: 'req',
        updateRules: [],
        md: 4,
        disabled: this.action === 'update'
      },
      user: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.domains.dialog.user'),
        items: [],
        createRules: 'req',
        updateRules: [],
        md: 4,
        disabled: this.action === 'update'
      },
      ip: {
        type: 'autocomplete',
        label: this.$t('hosting.servers.domains.dialog.ip'),
        items: [],
        createRules: 'req',
        updateRules: [],
        md: 4
      }
    }
  },
  methods: {
    serverDomainChangeEditedItem(changes) {
      this.serverDomainEditedItem = Object.assign({}, this.editedItem, changes)
    },
    async serverDomainSave(e) {
      this.saveLoading = true
      try {
        const v = e.$refs.form.validate()
        if (v) {
          this.serverDomainEditedItem.action = this.action
          const response = await this.$store.dispatch('hostingServers/saveDomain', this.serverDomainEditedItem)
          if (response.success === true) {
            if (this.action === 'update') {
              await this.$toast.success(this.$t('hosting.servers.domains.dialog.successUpdated'))
            } else {
              await this.$toast.success(this.$t('hosting.servers.domains.dialog.successAdded'))
            }
            this.serverDomainDialogClose()
          } else {
            await this.$toast.error(response.errors)
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.saveLoading = false
      }
    },
    serverDomainDialogClose() {
      this.$emit('close', this)
    }
  }
}
</script>

<style scoped>

</style>
